import { Button, Center, Container, Flex, Grid, Text } from '@mantine/core';
import { ShipmentCycleInventoryDetails } from 'shared';

type Props = {
  inventoryDetails?: ShipmentCycleInventoryDetails;
  goBack?: () => void;
};

export const InventoryCheck = ({ goBack, inventoryDetails }: Props) => {
  const { summary, details, total_quantity } = inventoryDetails ?? {};
  return (
    <Container fluid px={0}>
      <Grid gutter="xl">
        <Grid.Col span={6}>
          <Flex justify="space-between">
            <Text size={14} weight={600}>
              Active Users in Cycle
            </Text>

            <Text size={14}>{summary?.active_members}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          {details?.map(({ name, active_members }) => (
            <Flex key={name} justify="space-between" mb={33}>
              <Text size={14} weight={600}>
                {name} bottles
              </Text>

              <Text size={14}>{active_members}</Text>
            </Flex>
          ))}
        </Grid.Col>
        <Grid.Col span={12}>
          <Text size={18} weight={600}>
            Inventory to Cover the Cycle:
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Flex justify="space-between">
            <Text size={14} weight={600}>
              Bottles quantity total:
            </Text>

            <Text size={14}>{total_quantity}</Text>
          </Flex>
        </Grid.Col>
      </Grid>
      {goBack && (
        <Center sx={{ width: '100%' }}>
          <Button mt={40} variant="white" onClick={goBack}>
            Back to Cycle
          </Button>
        </Center>
      )}
    </Container>
  );
};
