import request from '../../request';
import {
  ShipmentCyclePayload,
  PaginatedRequest,
  PaginatedResponse,
  ShipmentCycleCollectionItemDto,
  ShipmentCycleDetailsDto,
  ShipmentCycleDto,
  ShipmentCycleEdit,
  ShipmentCycleInventoryDetails,
} from 'shared';
import { SHIPMENT_CYCLES_PATH } from '../../endpoints';

export default class ShipmentCycleService {
  static readonly getCollection = (params: PaginatedRequest) => {
    return request<PaginatedResponse<ShipmentCycleCollectionItemDto>>({
      url: `${SHIPMENT_CYCLES_PATH}`,
      params,
      method: 'get',
    });
  };

  static readonly getCustomers = ({
    cycleId,
    params,
  }: {
    cycleId: string;
    params: PaginatedRequest;
  }) => {
    return request<ShipmentCycleDetailsDto>({
      url: `${SHIPMENT_CYCLES_PATH}/${cycleId}/customers`,
      params,
      method: 'get',
    });
  };

  static readonly getOne = (id: string) => {
    return request<ShipmentCycleEdit>({
      url: `${SHIPMENT_CYCLES_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly create = (params: ShipmentCyclePayload) => {
    return request<ShipmentCyclePayload>({
      url: SHIPMENT_CYCLES_PATH,
      method: 'post',
      data: params,
    });
  };

  static readonly copy = ({ cycleId }: { cycleId: string }) => {
    return request<ShipmentCyclePayload>({
      url: `${SHIPMENT_CYCLES_PATH}/${cycleId}/copy`,
      method: 'post',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id: string;
    data: ShipmentCyclePayload;
  }) => {
    return request<ShipmentCycleDto>({
      url: `${SHIPMENT_CYCLES_PATH}/${id}`,
      method: 'patch',
      data,
    });
  };

  static readonly getInventoryDetails = (id: string) => {
    return request<ShipmentCycleInventoryDetails>({
      url: `${SHIPMENT_CYCLES_PATH}/${id}/inventory-details`,
      method: 'get',
    });
  };

  static readonly delete = (id: string) => {
    return request({
      url: `${SHIPMENT_CYCLES_PATH}/${id}`,
      method: 'delete',
    });
  };
}
