import { useMemo, useCallback } from 'react';
import { ShipmentCycleService } from 'App/api';
import { useApiMutation, useApiQuery, useQueryParams } from 'hooks';
import { ShipmentCycleCollectionItemOutput } from 'shared';
import { normalizeShipmentCycleSummary } from '../helpers';

type RequestFilter = { search_string: string };

export const useShipmentCycles = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params = useMemo(() => {
    return {
      page,
      page_size: 3,
      search_string,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading, refetch } = useApiQuery(
    ['getShipmentCycles', key],
    () => ShipmentCycleService.getCollection(params),
  );

  const { mutate: copy, isLoading: isCopied } = useApiMutation(
    ['copyShipmentCycles'],
    ShipmentCycleService.copy,
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const output: ShipmentCycleCollectionItemOutput[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map((cycle) => {
      const { id, summary, details } = cycle;
      return {
        id,
        copyId: `${id}${summary.name}`,
        ...normalizeShipmentCycleSummary(summary),
        details: details.map(normalizeShipmentCycleSummary),
      };
    });
  }, [data]);

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  return {
    output,
    page,
    search_string,
    isLoading: isLoading || isCopied,
    totalRecords: data?.total ?? 0,
    copy,
    updateFilters,
    handleSetSearchParams,
  };
};
